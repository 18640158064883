<template>
  <div class="h-full">
    <div class="w-full flex justify-center">
      <Title class="ml-5 my-10 font-normal text-primary inline-block">Choisissez selon vos envies et votre humeur</Title>
    </div>

    <MassagesList />

    <div class="p-8 mx-auto max-w-5xl text-center text-xl">
      <p class="p-4 pb-0">Les manoeuvres employées donnent un style différent à chacun de ces massages.</p>
      <p class="p-4">Elisabeth adaptera ces techniques à vos besoins pour personnaliser votre moment de détente.</p>
    </div>

    <div class="slideshow p-8 shadow-lg mb-8 lg:mx-auto lg:max-w-[95%]">
      <p class="p-4 text-md">Les temps indiqués pour les massages n'incluent pas les temps de préparation / rafraîchissement avant et après le massage.</p>

      <Slideshow class="h-[max(33vh,33vw)] md:h-[75vh]" :images="images" :captions="captions" autoplay />
    </div>
  </div>
</template>
<script>
import Title from '@/components/Title';
import MassagesList from '@/components/Massages';
import StraightLine from '@/components/StraightLine';
import Slideshow from '@/components/Slideshow';

export default {
  components: { Title, MassagesList, StraightLine, Slideshow },
  data() {
    return {
      images: [
        require('../assets/img/bathroom.jpg'),
        require('../assets/img/room-1.jpg'),
        require('../assets/img/room-2.jpg'),
        require('../assets/img/massage-1.jpg'),
        require('../assets/img/products-1.jpg'),
        require('../assets/img/massage-2.jpg'),
        require('../assets/img/products-2.jpg')
      ],
      captions: ['Une salle de bain privative sera mise à votre disposition']
    };
  }
};
</script>
<style></style>
