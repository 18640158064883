<template>
  <div>
    <div class="text-md p-4 pb-2" v-html="desc"></div>
    <div class="mt-4 flex items-center text-lg" :class="[durations.length > 1 ? 'justify-around' : 'float-right']">
      <template v-for="(_, index) in durations">
        <span :key="'slash' + index" v-if="index" class="font-bold">/</span>
        <div :key="'block' + index">
          <span v-if="durations[index]" class="text-sm rounded-full p-2 mr-2 text-white bg-[grey]">
            <ClockIcon class="inline-block mr-1" size="16" />{{ durations[index] }}
          </span>
          <span class="font-bold rounded-full p-2" :class="[theme.flat ? 'text-primary bg-white' : 'text-white bg-primary']">{{ prices[index] }}€</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { ClockIcon } from '@vue-hero-icons/outline';

export default {
  components: { ClockIcon },
  inject: ['theme'],
  props: {
    desc: {
      type: String,
      required: true
    },
    durations: {
      type: Array,
      default: []
    },
    prices: {
      type: Array,
      default: []
    }
  }
};
</script>

<style></style>
