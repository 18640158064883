<template>
  <div class="flex flex-col w-full items-center md:justify-center md:flex-row md:flex-wrap" :class="[theme.flat ? 'bg-accent' : '']">
    <Card
      v-for="(m, index) in massages"
      :key="`${index}-${m.name}`"
      class="mx-2 my-6 w-[90%] md:w-[40%] lg:w-[30%] xl:w-1/4 border-none"
      :class="[theme.flat ? 'shadow-none' : '', 'bg-secondary']"
    >
      <h3 class="text-center font-semibold text-lg">{{ m.title || m.name }}</h3>
      <h2 v-if="m.shortDesc" class="text-center text-sm">{{ m.shortDesc }}</h2>
      <StraightLine class="mb-2 mt-4 w-[60%]" />

      <div class="flex flex-col">
        <MassageDesc v-if="m.items" :desc="m.desc" :durations="m.items.map((i) => i.duration)" :prices="m.items.map((i) => i.price)" />
        <MassageDesc v-else :desc="m.desc" :durations="m.durations || [m.duration]" :prices="m.prices || [m.price]" />
      </div>
    </Card>
  </div>
</template>

<script>
import config from '@/assets/config.json';
import Card from './Card';
import StraightLine from './StraightLine';
import MassageDesc from './MassageDesc';

export default {
  components: { Card, StraightLine, MassageDesc },
  inject: ['theme'],
  computed: {
    massages() {
      return [...config.massages].sort((a, b) => {
        if (a.order === b.order) return 0;
        if (a.order > b.order) return 1;
        if (a.order < b.order) return -1;
      });
    }
  }
};
</script>

<style></style>
